<template>
  <v-dialog v-model="imageURLDialog" persistent max-width="400">
    <template v-slot:activator="{ on }">
      <v-btn icon color="grey lighten-1" v-on="on">
        <font-awesome-icon :icon="['fas', 'chevron-right']" />
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="px-2 px-sm-6">
        <v-row class="pb-3">
          <v-col
            cols="8"
            lg="10"
            class="d-flex flex-column justify-center align-start py-0"
          >
            <h3 class="title text-start text-break">Other Details</h3>
          </v-col>
          <v-col
            cols="4"
            lg="2"
            class="d-flex flex-column justify-center align-end py-0"
          >
            <v-btn icon color="grey lighten-1" class="mr-0 mb-0" @click="imageURLDialog = false">
              <v-icon class="dialog-close-icon">mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-row class="mx-0 my-3" align="center" justify="center" justify-md="end" no-gutters>
          <v-col cols="12" class="px-0 py-0">
            <v-row class="mx-0">
              <span class="text-left secondary--text subtitle-1">{{
                message
              }}</span>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="px-6">
        <v-row
          class="
            d-flex
            flex-column-reverse flex-sm-row
            align-center
            justify-space-between
            mx-0
          "
          no-gutters
        >
          <v-col
            cols="12"
            sm="4"
            class="d-flex flex-column align-center align-sm-start px-0"
          >
            <v-btn
              class="px-8 mx-0"
              color="secondary"
              @click="imageURLDialog = false"
              tile
              outlined
              depressed
              bottom
              >Cancel</v-btn
            >
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "AddImage",
  props: ["message"],
  data() {
    return {
      imageURLDialog: false,
    };
  },
};
</script>
<style scoped>
.img-placeholder {
  width: 100%;
  height: auto;
  border-radius: 5px;
}
</style>
<style>
.v-dialog .v-card .v-card__title .v-icon.dialog-close-icon {
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  width: 32px;
  height: 32px;
  font-size: 24px;
}
</style>
